/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportVersionTermConfig from 'Models/Security/Acl/CurriculumSupportVersionTermConfig';
import AdminVersionTermConfig from 'Models/Security/Acl/AdminVersionTermConfig';
import InstitutionAdminVersionTermConfig from 'Models/Security/Acl/InstitutionAdminVersionTermConfig';
import StudentAdvisorVersionTermConfig from 'Models/Security/Acl/StudentAdvisorVersionTermConfig';
import UserVersionTermConfig from 'Models/Security/Acl/UserVersionTermConfig';
import VisitorsVersionTermConfig from 'Models/Security/Acl/VisitorsVersionTermConfig';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IVersionTermConfigEntityAttributes extends IModelAttributes {
	minimum: number;
	maximum: number;
	increment: number;
	defaultLoad: number;

	termId: string;
	term: Models.TermEntity | Models.ITermEntityAttributes;
	programVersionId: string;
	programVersion: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('VersionTermConfigEntity', 'Version Term Config')
// % protected region % [Customise your entity metadata here] end
export default class VersionTermConfigEntity extends Model
	implements IVersionTermConfigEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportVersionTermConfig(),
		new AdminVersionTermConfig(),
		new InstitutionAdminVersionTermConfig(),
		new StudentAdvisorVersionTermConfig(),
		new UserVersionTermConfig(),
		new VisitorsVersionTermConfig(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Minimum'] on begin
	/**
	 * Minimum number of units
	 */
	@Validators.Required() // manually added decorator
	@observable
	@attribute<VersionTermConfigEntity, number>()
	@CRUD({
		name: 'Minimum',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minimum: number;
	// % protected region % [Modify props to the crud options here for attribute 'Minimum'] end

	// % protected region % [Modify props to the crud options here for attribute 'Maximum'] on begin
	/**
	 * Maximum number of units
	 */
	@Validators.Required() // manually added decorator
	@Validators.Numeric()
	@observable
	@attribute<VersionTermConfigEntity, number>()
	@CRUD({
		name: 'Maximum',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maximum: number;
	// % protected region % [Modify props to the crud options here for attribute 'Maximum'] end

	// % protected region % [Modify props to the crud options here for attribute 'Increment'] on begin
	/**
	 * Number of units incremented by when adjusting study plan term unit load
	 */
	@Validators.Required() // manually added decorator
	@Validators.Numeric()
	@observable
	@attribute<VersionTermConfigEntity, number>()
	@CRUD({
		name: 'Increment',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public increment: number;
	// % protected region % [Modify props to the crud options here for attribute 'Increment'] end

	// % protected region % [Modify props to the crud options here for attribute 'Default Load'] on begin
	/**
	 * Number of units a term defaults to, typically a full-time load
	 */
	@Validators.Required() // manually added decorator
	@Validators.Numeric()
	@observable
	@attribute<VersionTermConfigEntity, number>()
	@CRUD({
		name: 'Default Load',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public defaultLoad: number;
	// % protected region % [Modify props to the crud options here for attribute 'Default Load'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Term'] off begin
		name: 'Term',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.TermEntity,
		// % protected region % [Modify props to the crud options here for reference 'Term'] end
	})
	public termId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public term: Models.TermEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] off begin
		name: 'Program Version',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] end
	})
	public programVersionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programVersion: Models.ProgramVersionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IVersionTermConfigEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IVersionTermConfigEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.minimum !== undefined) {
				this.minimum = attributes.minimum;
			}
			if (attributes.maximum !== undefined) {
				this.maximum = attributes.maximum;
			}
			if (attributes.increment !== undefined) {
				this.increment = attributes.increment;
			}
			if (attributes.defaultLoad !== undefined) {
				this.defaultLoad = attributes.defaultLoad;
			}
			if (attributes.termId !== undefined) {
				this.termId = attributes.termId;
			}
			if (attributes.term !== undefined) {
				if (attributes.term === null) {
					this.term = attributes.term;
				} else if (attributes.term instanceof Models.TermEntity) {
					this.term = attributes.term;
					this.termId = attributes.term.id;
				} else {
					this.term = new Models.TermEntity(attributes.term);
					this.termId = this.term.id;
				}
			}
			if (attributes.programVersionId !== undefined) {
				this.programVersionId = attributes.programVersionId;
			}
			if (attributes.programVersion !== undefined) {
				if (attributes.programVersion === null) {
					this.programVersion = attributes.programVersion;
				} else if (attributes.programVersion instanceof Models.ProgramVersionEntity) {
					this.programVersion = attributes.programVersion;
					this.programVersionId = attributes.programVersion.id;
				} else {
					this.programVersion = new Models.ProgramVersionEntity(attributes.programVersion);
					this.programVersionId = this.programVersion.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		term {
			${Models.TermEntity.getAttributes().join('\n')}
		}
		programVersion {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(VersionTermConfigEntity.prototype, 'created');
CRUD(modifiedAttr)(VersionTermConfigEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
