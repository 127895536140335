/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportTermEntity from 'Models/Security/Acl/CurriculumSupportTermEntity';
import UserTermEntity from 'Models/Security/Acl/UserTermEntity';
import AdminTermEntity from 'Models/Security/Acl/AdminTermEntity';
import VisitorsTermEntity from 'Models/Security/Acl/VisitorsTermEntity';
import InstitutionAdminTermEntity from 'Models/Security/Acl/InstitutionAdminTermEntity';
import StudentAdvisorTermEntity from 'Models/Security/Acl/StudentAdvisorTermEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITermEntityAttributes extends IModelAttributes {
	name: string;
	order: number;
	cutoff: Date;

	courseOfferings: Array<
		| Models.CourseOfferingEntity
		| Models.ICourseOfferingEntityAttributes
	>;
	courseTerms: Array<
		| Models.TermCourseTerm
		| Models.ITermCourseTermAttributes
	>;
	generatedTerms: Array<
		| Models.GeneratedTermEntity
		| Models.IGeneratedTermEntityAttributes
	>;
	programVersionTermConfigs: Array<
		| Models.VersionTermConfigEntity
		| Models.IVersionTermConfigEntityAttributes
	>;
	studyPeriodId: string;
	studyPeriod: Models.StudyPeriodEntity | Models.IStudyPeriodEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TermEntity', 'Term')
// % protected region % [Customise your entity metadata here] end
export default class TermEntity extends Model
	implements ITermEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportTermEntity(),
		new UserTermEntity(),
		new AdminTermEntity(),
		new VisitorsTermEntity(),
		new InstitutionAdminTermEntity(),
		new StudentAdvisorTermEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Term name
	 */
	@Validators.Required()
	@observable
	@attribute<TermEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order'] on begin
	/**
	 * Term order
	 */
	@Validators.Required()
	@Validators.Integer()
	@Validators.Min(0)
	@Validators.Max(9)
	@observable
	@attribute<TermEntity, number>()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cutoff'] on begin
	/**
	 * The date in which the term is unable to have courses scheduled within in
	 */
	@observable
	@Validators.Date()
	@attribute<TermEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Cutoff',
		displayType: 'datepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public cutoff: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Cutoff'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] off begin
		name: 'Course Offerings',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.CourseOfferingEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'courseOfferings',
			oppositeEntity: () => Models.CourseOfferingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] end
	})
	public courseOfferings: Models.CourseOfferingEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Term'] off begin
		name: 'Course Term',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TermCourseTerm,
		optionEqualFunc: makeJoinEqualsFunc('courseTermId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'termEntity',
			oppositeEntityName: 'courseEntity',
			relationName: 'term',
			relationOppositeName: 'courseTerm',
			entity: () => Models.TermEntity,
			joinEntity: () => Models.TermCourseTerm,
			oppositeEntity: () => Models.CourseEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course Term'] end
	})
	public courseTerms: Models.TermCourseTerm[] = [];

	/**
	 * Generated Terms within generated plans for a given Institutions Term
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Generated Term'] off begin
		name: 'Generated Terms',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.GeneratedTermEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'generatedTerms',
			oppositeEntity: () => Models.GeneratedTermEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Generated Term'] end
	})
	public generatedTerms: Models.GeneratedTermEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version Term Config'] off begin
		name: 'Program Version Term Configs',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.VersionTermConfigEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'programVersionTermConfigs',
			oppositeEntity: () => Models.VersionTermConfigEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Program Version Term Config'] end
	})
	public programVersionTermConfigs: Models.VersionTermConfigEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Period'] off begin
		name: 'Study Period',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.StudyPeriodEntity,
		// % protected region % [Modify props to the crud options here for reference 'Study Period'] end
	})
	public studyPeriodId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public studyPeriod: Models.StudyPeriodEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITermEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITermEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.cutoff !== undefined) {
				if (attributes.cutoff === null) {
					this.cutoff = attributes.cutoff;
				} else {
					this.cutoff = moment(attributes.cutoff).toDate();
				}
			}
			if (attributes.courseOfferings !== undefined && Array.isArray(attributes.courseOfferings)) {
				for (const model of attributes.courseOfferings) {
					if (model instanceof Models.CourseOfferingEntity) {
						this.courseOfferings.push(model);
					} else {
						this.courseOfferings.push(new Models.CourseOfferingEntity(model));
					}
				}
			}
			if (attributes.courseTerms !== undefined && Array.isArray(attributes.courseTerms)) {
				for (const model of attributes.courseTerms) {
					if (model instanceof Models.TermCourseTerm) {
						this.courseTerms.push(model);
					} else {
						this.courseTerms.push(new Models.TermCourseTerm(model));
					}
				}
			}
			if (attributes.generatedTerms !== undefined && Array.isArray(attributes.generatedTerms)) {
				for (const model of attributes.generatedTerms) {
					if (model instanceof Models.GeneratedTermEntity) {
						this.generatedTerms.push(model);
					} else {
						this.generatedTerms.push(new Models.GeneratedTermEntity(model));
					}
				}
			}
			if (attributes.programVersionTermConfigs !== undefined && Array.isArray(attributes.programVersionTermConfigs)) {
				for (const model of attributes.programVersionTermConfigs) {
					if (model instanceof Models.VersionTermConfigEntity) {
						this.programVersionTermConfigs.push(model);
					} else {
						this.programVersionTermConfigs.push(new Models.VersionTermConfigEntity(model));
					}
				}
			}
			if (attributes.studyPeriodId !== undefined) {
				this.studyPeriodId = attributes.studyPeriodId;
			}
			if (attributes.studyPeriod !== undefined) {
				if (attributes.studyPeriod === null) {
					this.studyPeriod = attributes.studyPeriod;
				} else if (attributes.studyPeriod instanceof Models.StudyPeriodEntity) {
					this.studyPeriod = attributes.studyPeriod;
					this.studyPeriodId = attributes.studyPeriod.id;
				} else {
					this.studyPeriod = new Models.StudyPeriodEntity(attributes.studyPeriod);
					this.studyPeriodId = this.studyPeriod.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		courseTerms {
			${Models.TermCourseTerm.getAttributes().join('\n')}
			courseTerm {
				${Models.CourseEntity.getAttributes().join('\n')}
			}
		}
		courseOfferings {
			${Models.CourseOfferingEntity.getAttributes().join('\n')}
		}
		generatedTerms {
			${Models.GeneratedTermEntity.getAttributes().join('\n')}
		}
		programVersionTermConfigs {
			${Models.VersionTermConfigEntity.getAttributes().join('\n')}
		}
		studyPeriod {
			${Models.StudyPeriodEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			courseTerms: {},
			courseOfferings: {},
			generatedTerms: {},
			programVersionTermConfigs: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'courseOfferings',
							'courseTerms',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TermEntity.prototype, 'created');
CRUD(modifiedAttr)(TermEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
