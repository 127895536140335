/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportPlanSelectionEntity from 'Models/Security/Acl/CurriculumSupportPlanSelectionEntity';
import AdminPlanSelectionEntity from 'Models/Security/Acl/AdminPlanSelectionEntity';
import InstitutionAdminPlanSelectionEntity from 'Models/Security/Acl/InstitutionAdminPlanSelectionEntity';
import StudentAdvisorPlanSelectionEntity from 'Models/Security/Acl/StudentAdvisorPlanSelectionEntity';
import UserPlanSelectionEntity from 'Models/Security/Acl/UserPlanSelectionEntity';
import VisitorsPlanSelectionEntity from 'Models/Security/Acl/VisitorsPlanSelectionEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPlanSelectionEntityAttributes extends IModelAttributes {
	completionStatus: Enums.completionStatus;

	courseId: string;
	course: Models.CourseEntity | Models.ICourseEntityAttributes;
	courseOfferingId?: string;
	courseOffering?: Models.CourseOfferingEntity | Models.ICourseOfferingEntityAttributes;
	studyPlanId: string;
	studyPlan: Models.StudyPlanEntity | Models.IStudyPlanEntityAttributes;
	locationId: string;
	location: Models.RequirementEntity | Models.IRequirementEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PlanSelectionEntity', 'Plan Selection')
// % protected region % [Customise your entity metadata here] end
export default class PlanSelectionEntity extends Model
	implements IPlanSelectionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportPlanSelectionEntity(),
		new AdminPlanSelectionEntity(),
		new InstitutionAdminPlanSelectionEntity(),
		new StudentAdvisorPlanSelectionEntity(),
		new UserPlanSelectionEntity(),
		new VisitorsPlanSelectionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Completion Status'] off begin
	/**
	 * Determines whether this selection marks a completed course or a course to be scheduled
	 */
	@observable
	@attribute<PlanSelectionEntity, Enums.completionStatus>()
	@CRUD({
		name: 'Completion Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.completionStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.completionStatusOptions),
		displayFunction: (attr: Enums.completionStatus) => Enums.completionStatusOptions[attr],
	})
	public completionStatus: Enums.completionStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Completion Status'] end

	/**
	 * Selected instances of this course against a plan, either completed or to be scheduled within the plan
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course'] off begin
		name: 'Course',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.CourseEntity,
		// % protected region % [Modify props to the crud options here for reference 'Course'] end
	})
	public courseId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public course: Models.CourseEntity;

	/**
	 * Selected Course Offerings to be scheduled
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] off begin
		name: 'Course Offering',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.CourseOfferingEntity,
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] end
	})
	public courseOfferingId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public courseOffering: Models.CourseOfferingEntity;

	/**
	 * Selected instances of courses within a plan, these may be selected Course Offerings or Completed Courses
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] off begin
		name: 'Study Plan',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.StudyPlanEntity,
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] end
	})
	public studyPlanId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public studyPlan: Models.StudyPlanEntity;

	/**
	 * Courses that have been selected within a plan
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Location'] off begin
		name: 'Location',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.RequirementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Location'] end
	})
	public locationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public location: Models.RequirementEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPlanSelectionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPlanSelectionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.completionStatus !== undefined) {
				this.completionStatus = attributes.completionStatus;
			}
			if (attributes.courseId !== undefined) {
				this.courseId = attributes.courseId;
			}
			if (attributes.course !== undefined) {
				if (attributes.course === null) {
					this.course = attributes.course;
				} else if (attributes.course instanceof Models.CourseEntity) {
					this.course = attributes.course;
					this.courseId = attributes.course.id;
				} else {
					this.course = new Models.CourseEntity(attributes.course);
					this.courseId = this.course.id;
				}
			}
			if (attributes.courseOfferingId !== undefined) {
				this.courseOfferingId = attributes.courseOfferingId;
			}
			if (attributes.courseOffering !== undefined) {
				if (attributes.courseOffering === null) {
					this.courseOffering = attributes.courseOffering;
				} else if (attributes.courseOffering instanceof Models.CourseOfferingEntity) {
					this.courseOffering = attributes.courseOffering;
					this.courseOfferingId = attributes.courseOffering.id;
				} else {
					this.courseOffering = new Models.CourseOfferingEntity(attributes.courseOffering);
					this.courseOfferingId = this.courseOffering.id;
				}
			}
			if (attributes.studyPlanId !== undefined) {
				this.studyPlanId = attributes.studyPlanId;
			}
			if (attributes.studyPlan !== undefined) {
				if (attributes.studyPlan === null) {
					this.studyPlan = attributes.studyPlan;
				} else if (attributes.studyPlan instanceof Models.StudyPlanEntity) {
					this.studyPlan = attributes.studyPlan;
					this.studyPlanId = attributes.studyPlan.id;
				} else {
					this.studyPlan = new Models.StudyPlanEntity(attributes.studyPlan);
					this.studyPlanId = this.studyPlan.id;
				}
			}
			if (attributes.locationId !== undefined) {
				this.locationId = attributes.locationId;
			}
			if (attributes.location !== undefined) {
				if (attributes.location === null) {
					this.location = attributes.location;
				} else if (attributes.location instanceof Models.RequirementEntity) {
					this.location = attributes.location;
					this.locationId = attributes.location.id;
				} else {
					this.location = new Models.RequirementEntity(attributes.location);
					this.locationId = this.location.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		course {
			${Models.CourseEntity.getAttributes().join('\n')}
		}
		courseOffering {
			${Models.CourseOfferingEntity.getAttributes().join('\n')}
		}
		studyPlan {
			${Models.StudyPlanEntity.getAttributes().join('\n')}
		}
		location {
			${Models.RequirementEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PlanSelectionEntity.prototype, 'created');
CRUD(modifiedAttr)(PlanSelectionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
