/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import CurriculumSupportCourseReferenceManyToMany from '../Security/Acl/CurriculumSupportCourseReferenceManyToMany';
import AdminCourseReferenceManyToMany from '../Security/Acl/AdminCourseReferenceManyToMany';
import StudentAdvisorCourseReferenceManyToMany from '../Security/Acl/StudentAdvisorCourseReferenceManyToMany';
import InstitutionAdminCourseReferenceManyToMany from '../Security/Acl/InstitutionAdminCourseReferenceManyToMany';
import UserCourseReferenceManyToMany from '../Security/Acl/UserCourseReferenceManyToMany';
import VisitorsCourseReferenceManyToMany from '../Security/Acl/VisitorsCourseReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICourseSetCourseAttributes extends IModelAttributes {
	courseSetId: string;
	courseId: string;

	courseSet: Models.CourseSetEntity | Models.ICourseSetEntityAttributes;
	course: Models.CourseEntity | Models.ICourseEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CourseSetCourse')
export default class CourseSetCourse
	extends Model
	implements ICourseSetCourseAttributes {
	public static acls: IAcl[] = [
		new CurriculumSupportCourseReferenceManyToMany(),
		new AdminCourseReferenceManyToMany(),
		new StudentAdvisorCourseReferenceManyToMany(),
		new InstitutionAdminCourseReferenceManyToMany(),
		new UserCourseReferenceManyToMany(),
		new VisitorsCourseReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public courseSetId: string;

	@observable
	@attribute()
	public courseId: string;

	@observable
	@attribute({ isReference: true })
	public courseSet: Models.CourseSetEntity;

	@observable
	@attribute({ isReference: true })
	public course: Models.CourseEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICourseSetCourseAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.courseSetId) {
				this.courseSetId = attributes.courseSetId;
			}
			if (attributes.courseId) {
				this.courseId = attributes.courseId;
			}

			if (attributes.courseSet) {
				if (attributes.courseSet instanceof Models.CourseSetEntity) {
					this.courseSet = attributes.courseSet;
					this.courseSetId = attributes.courseSet.id;
				} else {
					this.courseSet = new Models.CourseSetEntity(attributes.courseSet);
					this.courseSetId = this.courseSet.id;
				}
			} else if (attributes.courseSetId !== undefined) {
				this.courseSetId = attributes.courseSetId;
			}

			if (attributes.course) {
				if (attributes.course instanceof Models.CourseEntity) {
					this.course = attributes.course;
					this.courseId = attributes.course.id;
				} else {
					this.course = new Models.CourseEntity(attributes.course);
					this.courseId = this.course.id;
				}
			} else if (attributes.courseId !== undefined) {
				this.courseId = attributes.courseId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
