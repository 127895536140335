/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] on begin
import {
	AdminEntity, CurriculumSupportEntity, InstitutionAdminEntity, StudentAdvisorEntity, UserEntity,
} from 'Models/Entities';
// % protected region % [Add any extra imports here] end

export type completionStatus =
	// % protected region % [Override completionStatus keys here] off begin
	'INCOMPLETE' |
	'COMPLETE';
	// % protected region % [Override completionStatus keys here] end

export const completionStatusOptions: { [key in completionStatus]: string } = {
	// % protected region % [Override completionStatus display fields here] off begin
	INCOMPLETE: 'Incomplete',
	COMPLETE: 'Complete',
	// % protected region % [Override completionStatus display fields here] end
};

export type importStatus =
	// % protected region % [Override importStatus keys here] off begin
	'AWAITING_PROCESSING' |
	'PROCESSING' |
	'READY_FOR_REVIEW' |
	'AWAITING_UPLOADING' |
	'UPLOADING' |
	'COMPLETE' |
	'ERROR';
	// % protected region % [Override importStatus keys here] end

export const importStatusOptions: { [key in importStatus]: string } = {
	// % protected region % [Override importStatus display fields here] off begin
	AWAITING_PROCESSING: 'AWAITING PROCESSING',
	PROCESSING: 'PROCESSING',
	READY_FOR_REVIEW: 'READY FOR REVIEW',
	AWAITING_UPLOADING: 'AWAITING UPLOADING',
	UPLOADING: 'UPLOADING',
	COMPLETE: 'COMPLETE',
	ERROR: 'ERROR',
	// % protected region % [Override importStatus display fields here] end
};

export type importType =
	// % protected region % [Override importType keys here] off begin
	'PROGRAM' |
	'COURSE';
	// % protected region % [Override importType keys here] end

export const importTypeOptions: { [key in importType]: string } = {
	// % protected region % [Override importType display fields here] off begin
	PROGRAM: 'PROGRAM',
	COURSE: 'COURSE',
	// % protected region % [Override importType display fields here] end
};

export type logicCondition =
	// % protected region % [Override logicCondition keys here] off begin
	'OR' |
	'AND';
	// % protected region % [Override logicCondition keys here] end

export const logicConditionOptions: { [key in logicCondition]: string } = {
	// % protected region % [Override logicCondition display fields here] off begin
	OR: 'OR',
	AND: 'AND',
	// % protected region % [Override logicCondition display fields here] end
};

export type programStatus =
	// % protected region % [Override programStatus keys here] off begin
	'DRAFT' |
	'IN_REVIEW' |
	'PUBLISHED' |
	'ARCHIVED' |
	'APPROVED';
	// % protected region % [Override programStatus keys here] end

export const programStatusOptions = {
	// % protected region % [Override programStatus display fields here] off begin
	DRAFT: 'DRAFT',
	IN_REVIEW: 'IN REVIEW',
	PUBLISHED: 'PUBLISHED',
	ARCHIVED: 'ARCHIVED',
	APPROVED: 'APPROVED',
	// % protected region % [Override programStatus display fields here] end
} as const;

export type requirementCategory =
	// % protected region % [Override requirementCategory keys here] off begin
	'REQUIREMENTS' |
	'COURSES';
	// % protected region % [Override requirementCategory keys here] end

export const requirementCategoryOptions: { [key in requirementCategory]: string } = {
	// % protected region % [Override requirementCategory display fields here] off begin
	REQUIREMENTS: 'REQUIREMENTS',
	COURSES: 'COURSES',
	// % protected region % [Override requirementCategory display fields here] end
};

export type requirementCondition =
	// % protected region % [Override requirementCondition keys here] off begin
	'AND' |
	'OR';
	// % protected region % [Override requirementCondition keys here] end

export const requirementConditionOptions: { [key in requirementCondition]: string } = {
	// % protected region % [Override requirementCondition display fields here] off begin
	AND: 'AND',
	OR: 'OR',
	// % protected region % [Override requirementCondition display fields here] end
};

export type requirementType =
	// % protected region % [Override requirementType keys here] off begin
	'REQUIREMENT_OPTION' |
	'COMPONENT';
	// % protected region % [Override requirementType keys here] end

export const requirementTypeOptions: { [key in requirementType]: string } = {
	// % protected region % [Override requirementType display fields here] off begin
	REQUIREMENT_OPTION: 'REQUIREMENT OPTION',
	COMPONENT: 'COMPONENT',
	// % protected region % [Override requirementType display fields here] end
};

export type requisiteType =
	// % protected region % [Override requisiteType keys here] off begin
	'PRE' |
	'CO' |
	'ANTI' |
	'SEQ';
	// % protected region % [Override requisiteType keys here] end

export const requisiteTypeOptions: { [key in requisiteType]: string } = {
	// % protected region % [Override requisiteType display fields here] off begin
	PRE: 'PRE',
	CO: 'CO',
	ANTI: 'ANTI',
	SEQ: 'SEQ',
	// % protected region % [Override requisiteType display fields here] end
};

export type unitConditionType =
	// % protected region % [Override unitConditionType keys here] off begin
	'MIN' |
	'MAX' |
	'EQUAL';
	// % protected region % [Override unitConditionType keys here] end

export const unitConditionTypeOptions: { [key in unitConditionType]: string } = {
	// % protected region % [Override unitConditionType display fields here] off begin
	MIN: 'MIN',
	MAX: 'MAX',
	EQUAL: 'EQUAL',
	// % protected region % [Override unitConditionType display fields here] end
};

// % protected region % [Add any extra enums here] on begin
// eslint-disable-next-line max-len
export type UserModels = UserEntity | AdminEntity | InstitutionAdminEntity | CurriculumSupportEntity | StudentAdvisorEntity;
export type UserModelType<T> = T extends UserModels ? T : never;

export const userDiscriminatorRoleMap = {
	InstitutionAdminEntity: 'InstitutionAdmin',
	CurriculumSupportEntity: 'CurriculumSupport',
	StudentAdvisorEntity: 'StudentAdvisor',
	UserEntity: 'User',
	AdminEntity: 'Admin',
	User: 'Super Administrators',
} as const;

export type UserDiscriminators = (keyof typeof userDiscriminatorRoleMap);
export type UserRoles = (typeof userDiscriminatorRoleMap)[keyof typeof userDiscriminatorRoleMap] | 'Visitors';

// User Groups are also the User Discriminators
export const userModelDisplayNameMap = {
	InstitutionAdminEntity: 'Institution Admin',
	CurriculumSupportEntity: 'Curriculum Support',
	StudentAdvisorEntity: 'Academic Advisor',
	UserEntity: 'Student',
	AdminEntity: 'Administrator',
	User: 'Super Administrator',
} as const;

export type UserEntities = keyof typeof userModelDisplayNameMap;
// % protected region % [Add any extra enums here] end
