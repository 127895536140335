/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import CurriculumSupportStudyPlanRequirementReferenceManyToMany from '../Security/Acl/CurriculumSupportStudyPlanRequirementReferenceManyToMany';
import StudentAdvisorStudyPlanRequirementReferenceManyToMany from '../Security/Acl/StudentAdvisorStudyPlanRequirementReferenceManyToMany';
import AdminStudyPlanRequirementReferenceManyToMany from '../Security/Acl/AdminStudyPlanRequirementReferenceManyToMany';
import InstitutionAdminStudyPlanRequirementReferenceManyToMany from '../Security/Acl/InstitutionAdminStudyPlanRequirementReferenceManyToMany';
import UserStudyPlanRequirementReferenceManyToMany from '../Security/Acl/UserStudyPlanRequirementReferenceManyToMany';
import VisitorsStudyPlanRequirementReferenceManyToMany from '../Security/Acl/VisitorsStudyPlanRequirementReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IRequiringStudyPlanStudyPlanRequirementAttributes extends IModelAttributes {
	requiringStudyPlanId: string;
	studyPlanRequirementId: string;

	requiringStudyPlan: Models.StudyPlanEntity | Models.IStudyPlanEntityAttributes;
	studyPlanRequirement: Models.RequirementEntity | Models.IRequirementEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('RequiringStudyPlanStudyPlanRequirement')
export default class RequiringStudyPlanStudyPlanRequirement
	extends Model
	implements IRequiringStudyPlanStudyPlanRequirementAttributes {
	public static acls: IAcl[] = [
		new CurriculumSupportStudyPlanRequirementReferenceManyToMany(),
		new StudentAdvisorStudyPlanRequirementReferenceManyToMany(),
		new AdminStudyPlanRequirementReferenceManyToMany(),
		new InstitutionAdminStudyPlanRequirementReferenceManyToMany(),
		new UserStudyPlanRequirementReferenceManyToMany(),
		new VisitorsStudyPlanRequirementReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public requiringStudyPlanId: string;

	@observable
	@attribute()
	public studyPlanRequirementId: string;

	@observable
	@attribute({ isReference: true })
	public requiringStudyPlan: Models.StudyPlanEntity;

	@observable
	@attribute({ isReference: true })
	public studyPlanRequirement: Models.RequirementEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IRequiringStudyPlanStudyPlanRequirementAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.requiringStudyPlanId) {
				this.requiringStudyPlanId = attributes.requiringStudyPlanId;
			}
			if (attributes.studyPlanRequirementId) {
				this.studyPlanRequirementId = attributes.studyPlanRequirementId;
			}

			if (attributes.requiringStudyPlan) {
				if (attributes.requiringStudyPlan instanceof Models.StudyPlanEntity) {
					this.requiringStudyPlan = attributes.requiringStudyPlan;
					this.requiringStudyPlanId = attributes.requiringStudyPlan.id;
				} else {
					this.requiringStudyPlan = new Models.StudyPlanEntity(attributes.requiringStudyPlan);
					this.requiringStudyPlanId = this.requiringStudyPlan.id;
				}
			} else if (attributes.requiringStudyPlanId !== undefined) {
				this.requiringStudyPlanId = attributes.requiringStudyPlanId;
			}

			if (attributes.studyPlanRequirement) {
				if (attributes.studyPlanRequirement instanceof Models.RequirementEntity) {
					this.studyPlanRequirement = attributes.studyPlanRequirement;
					this.studyPlanRequirementId = attributes.studyPlanRequirement.id;
				} else {
					this.studyPlanRequirement = new Models.RequirementEntity(attributes.studyPlanRequirement);
					this.studyPlanRequirementId = this.studyPlanRequirement.id;
				}
			} else if (attributes.studyPlanRequirementId !== undefined) {
				this.studyPlanRequirementId = attributes.studyPlanRequirementId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
