/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import CurriculumSupportCourseTermReferenceManyToMany from '../Security/Acl/CurriculumSupportCourseTermReferenceManyToMany';
import AdminCourseTermReferenceManyToMany from '../Security/Acl/AdminCourseTermReferenceManyToMany';
import VisitorsCourseTermReferenceManyToMany from '../Security/Acl/VisitorsCourseTermReferenceManyToMany';
import InstitutionAdminCourseTermReferenceManyToMany from '../Security/Acl/InstitutionAdminCourseTermReferenceManyToMany';
import UserCourseTermReferenceManyToMany from '../Security/Acl/UserCourseTermReferenceManyToMany';
import StudentAdvisorCourseTermReferenceManyToMany from '../Security/Acl/StudentAdvisorCourseTermReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITermCourseTermAttributes extends IModelAttributes {
	termId: string;
	courseTermId: string;

	term: Models.TermEntity | Models.ITermEntityAttributes;
	courseTerm: Models.CourseEntity | Models.ICourseEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TermCourseTerm')
export default class TermCourseTerm
	extends Model
	implements ITermCourseTermAttributes {
	public static acls: IAcl[] = [
		new CurriculumSupportCourseTermReferenceManyToMany(),
		new AdminCourseTermReferenceManyToMany(),
		new VisitorsCourseTermReferenceManyToMany(),
		new InstitutionAdminCourseTermReferenceManyToMany(),
		new UserCourseTermReferenceManyToMany(),
		new StudentAdvisorCourseTermReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public termId: string;

	@observable
	@attribute()
	public courseTermId: string;

	@observable
	@attribute({ isReference: true })
	public term: Models.TermEntity;

	@observable
	@attribute({ isReference: true })
	public courseTerm: Models.CourseEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITermCourseTermAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.termId) {
				this.termId = attributes.termId;
			}
			if (attributes.courseTermId) {
				this.courseTermId = attributes.courseTermId;
			}

			if (attributes.term) {
				if (attributes.term instanceof Models.TermEntity) {
					this.term = attributes.term;
					this.termId = attributes.term.id;
				} else {
					this.term = new Models.TermEntity(attributes.term);
					this.termId = this.term.id;
				}
			} else if (attributes.termId !== undefined) {
				this.termId = attributes.termId;
			}

			if (attributes.courseTerm) {
				if (attributes.courseTerm instanceof Models.CourseEntity) {
					this.courseTerm = attributes.courseTerm;
					this.courseTermId = attributes.courseTerm.id;
				} else {
					this.courseTerm = new Models.CourseEntity(attributes.courseTerm);
					this.courseTermId = this.courseTerm.id;
				}
			} else if (attributes.courseTermId !== undefined) {
				this.courseTermId = attributes.courseTermId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
