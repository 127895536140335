/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportGeneratedTermEntity from 'Models/Security/Acl/CurriculumSupportGeneratedTermEntity';
import VisitorsGeneratedTermEntity from 'Models/Security/Acl/VisitorsGeneratedTermEntity';
import AdminGeneratedTermEntity from 'Models/Security/Acl/AdminGeneratedTermEntity';
import UserGeneratedTermEntity from 'Models/Security/Acl/UserGeneratedTermEntity';
import StudentAdvisorGeneratedTermEntity from 'Models/Security/Acl/StudentAdvisorGeneratedTermEntity';
import InstitutionAdminGeneratedTermEntity from 'Models/Security/Acl/InstitutionAdminGeneratedTermEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGeneratedTermEntityAttributes extends IModelAttributes {
	termOrder: number;
	periodOrder: number;
	year: number;
	studyLoad: number;

	generatedCourseOfferings: Array<
		| Models.GeneratedTermGeneratedCourseOffering
		| Models.IGeneratedTermGeneratedCourseOfferingAttributes
	>;
	termId: string;
	term: Models.TermEntity | Models.ITermEntityAttributes;
	generatedPlanId: string;
	generatedPlan: Models.GeneratedPlanEntity | Models.IGeneratedPlanEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('GeneratedTermEntity', 'Generated Term')
// % protected region % [Customise your entity metadata here] end
export default class GeneratedTermEntity extends Model
	implements IGeneratedTermEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportGeneratedTermEntity(),
		new VisitorsGeneratedTermEntity(),
		new AdminGeneratedTermEntity(),
		new UserGeneratedTermEntity(),
		new StudentAdvisorGeneratedTermEntity(),
		new InstitutionAdminGeneratedTermEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Term Order'] off begin
	@Validators.Required()
	@Validators.Min(0)
	@Validators.Max(100)
	@Validators.Integer()
	@observable
	@attribute<GeneratedTermEntity, number>()
	@CRUD({
		name: 'Term Order',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public termOrder: number;
	// % protected region % [Modify props to the crud options here for attribute 'Term Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Period Order'] off begin
	/**
	 * Which half of the year the courses are grouped in
	 */
	@Validators.Required()
	@Validators.Min(0)
	@Validators.Max(1)
	@Validators.Integer()
	@observable
	@attribute<GeneratedTermEntity, number>()
	@CRUD({
		name: 'Period Order',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public periodOrder: number;
	// % protected region % [Modify props to the crud options here for attribute 'Period Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Year'] off begin
	@Validators.Required()
	@Validators.Min(2000)
	@Validators.Max(4000)
	@Validators.Integer()
	@observable
	@attribute<GeneratedTermEntity, number>()
	@CRUD({
		name: 'Year',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public year: number;
	// % protected region % [Modify props to the crud options here for attribute 'Year'] end

	// % protected region % [Modify props to the crud options here for attribute 'Study Load'] off begin
	/**
	 * The configured unit capacity of this term
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<GeneratedTermEntity, number>()
	@CRUD({
		name: 'Study Load',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public studyLoad: number;
	// % protected region % [Modify props to the crud options here for attribute 'Study Load'] end

	@Validators.Length(1)
	@Validators.Required()
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Generated Course Offering'] off begin
		name: 'Generated Course Offering',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.GeneratedTermGeneratedCourseOffering,
		optionEqualFunc: makeJoinEqualsFunc('generatedCourseOfferingId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'generatedTermEntity',
			oppositeEntityName: 'courseOfferingEntity',
			relationName: 'generatedTerm',
			relationOppositeName: 'generatedCourseOffering',
			entity: () => Models.GeneratedTermEntity,
			joinEntity: () => Models.GeneratedTermGeneratedCourseOffering,
			oppositeEntity: () => Models.CourseOfferingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Generated Course Offering'] end
	})
	public generatedCourseOfferings: Models.GeneratedTermGeneratedCourseOffering[] = [];

	/**
	 * Generated Terms within generated plans for a given Institutions Term
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Term'] off begin
		name: 'Term',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.TermEntity,
		// % protected region % [Modify props to the crud options here for reference 'Term'] end
	})
	public termId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public term: Models.TermEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Generated Plan'] off begin
		name: 'Generated Plan',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.GeneratedPlanEntity,
		// % protected region % [Modify props to the crud options here for reference 'Generated Plan'] end
	})
	public generatedPlanId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public generatedPlan: Models.GeneratedPlanEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IGeneratedTermEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IGeneratedTermEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.termOrder !== undefined) {
				this.termOrder = attributes.termOrder;
			}
			if (attributes.periodOrder !== undefined) {
				this.periodOrder = attributes.periodOrder;
			}
			if (attributes.year !== undefined) {
				this.year = attributes.year;
			}
			if (attributes.studyLoad !== undefined) {
				this.studyLoad = attributes.studyLoad;
			}
			if (attributes.generatedCourseOfferings !== undefined && Array.isArray(attributes.generatedCourseOfferings)) {
				for (const model of attributes.generatedCourseOfferings) {
					if (model instanceof Models.GeneratedTermGeneratedCourseOffering) {
						this.generatedCourseOfferings.push(model);
					} else {
						this.generatedCourseOfferings.push(new Models.GeneratedTermGeneratedCourseOffering(model));
					}
				}
			}
			if (attributes.termId !== undefined) {
				this.termId = attributes.termId;
			}
			if (attributes.term !== undefined) {
				if (attributes.term === null) {
					this.term = attributes.term;
				} else if (attributes.term instanceof Models.TermEntity) {
					this.term = attributes.term;
					this.termId = attributes.term.id;
				} else {
					this.term = new Models.TermEntity(attributes.term);
					this.termId = this.term.id;
				}
			}
			if (attributes.generatedPlanId !== undefined) {
				this.generatedPlanId = attributes.generatedPlanId;
			}
			if (attributes.generatedPlan !== undefined) {
				if (attributes.generatedPlan === null) {
					this.generatedPlan = attributes.generatedPlan;
				} else if (attributes.generatedPlan instanceof Models.GeneratedPlanEntity) {
					this.generatedPlan = attributes.generatedPlan;
					this.generatedPlanId = attributes.generatedPlan.id;
				} else {
					this.generatedPlan = new Models.GeneratedPlanEntity(attributes.generatedPlan);
					this.generatedPlanId = this.generatedPlan.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		generatedCourseOfferings {
			${Models.GeneratedTermGeneratedCourseOffering.getAttributes().join('\n')}
			generatedCourseOffering {
				${Models.CourseOfferingEntity.getAttributes().join('\n')}
			}
		}
		term {
			${Models.TermEntity.getAttributes().join('\n')}
		}
		generatedPlan {
			${Models.GeneratedPlanEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			generatedCourseOfferings: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'generatedCourseOfferings',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(GeneratedTermEntity.prototype, 'created');
CRUD(modifiedAttr)(GeneratedTermEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
