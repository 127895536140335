/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportLexiconEntity from 'Models/Security/Acl/CurriculumSupportLexiconEntity';
import VisitorsLexiconEntity from 'Models/Security/Acl/VisitorsLexiconEntity';
import UserLexiconEntity from 'Models/Security/Acl/UserLexiconEntity';
import StudentAdvisorLexiconEntity from 'Models/Security/Acl/StudentAdvisorLexiconEntity';
import InstitutionAdminLexiconEntity from 'Models/Security/Acl/InstitutionAdminLexiconEntity';
import AdminLexiconEntity from 'Models/Security/Acl/AdminLexiconEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ILexiconEntityAttributes extends IModelAttributes {
	academicAdvisor: string;
	administrator: string;
	classDisplayName: string;
	code: string;
	commencingYear: string;
	component: string;
	components: string;
	termsAndConditions: string;
	completedCredit: string;
	course: string;
	courses: string;
	curriculumSupport: string;
	discipline: string;
	duplicate: string;
	duplicates: string;
	duplicateSelectionSummary: string;
	enrol: string;
	faculty: string;
	location: string;
	program: string;
	requirementsFulfilmentCheck: string;
	requisites: string;
	requisiteWarning: string;
	rules: string;
	school: string;
	selectionNotice: string;
	selectionNoticeText: string;
	studentID: string;
	studyPeriod: string;
	studyPlan: string;
	studyPlans: string;
	structure: string;
	structures: string;
	term: string;
	title: string;
	units: string;

	institutionId?: string;
	institution?: Models.InstitutionEntity | Models.IInstitutionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('LexiconEntity', 'Lexicon')
// % protected region % [Customise your entity metadata here] end
export default class LexiconEntity extends Model
	implements ILexiconEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportLexiconEntity(),
		new VisitorsLexiconEntity(),
		new UserLexiconEntity(),
		new StudentAdvisorLexiconEntity(),
		new InstitutionAdminLexiconEntity(),
		new AdminLexiconEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Academic Advisor'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Academic Advisor',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public academicAdvisor: string;
	// % protected region % [Modify props to the crud options here for attribute 'Academic Advisor'] end

	// % protected region % [Modify props to the crud options here for attribute 'Administrator'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Administrator',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public administrator: string;
	// % protected region % [Modify props to the crud options here for attribute 'Administrator'] end

	// % protected region % [Modify props to the crud options here for attribute 'Class Display Name'] on begin
	/**
	 * Display Name suffix added as Class is a protected keyword
	 */
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Class',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public classDisplayName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Class Display Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Code'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Commencing Year'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Commencing Year',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public commencingYear: string;
	// % protected region % [Modify props to the crud options here for attribute 'Commencing Year'] end

	// % protected region % [Modify props to the crud options here for attribute 'Component'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Component',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public component: string;
	// % protected region % [Modify props to the crud options here for attribute 'Component'] end

	// % protected region % [Modify props to the crud options here for attribute 'Components'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Components',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public components: string;
	// % protected region % [Modify props to the crud options here for attribute 'Components'] end

	// % protected region % [Modify props to the crud options here for attribute 'Terms And Conditions'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Terms And Conditions',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public termsAndConditions: string;
	// % protected region % [Modify props to the crud options here for attribute 'Terms And Conditions'] end

	// % protected region % [Modify props to the crud options here for attribute 'Completed Credit'] off begin
	/**
	 * Completed/Credit label on courses marked as such
	 */
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Completed Credit',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public completedCredit: string;
	// % protected region % [Modify props to the crud options here for attribute 'Completed Credit'] end

	// % protected region % [Modify props to the crud options here for attribute 'Course'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Course',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public course: string;
	// % protected region % [Modify props to the crud options here for attribute 'Course'] end

	// % protected region % [Modify props to the crud options here for attribute 'Courses'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Courses',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public courses: string;
	// % protected region % [Modify props to the crud options here for attribute 'Courses'] end

	// % protected region % [Modify props to the crud options here for attribute 'Curriculum Support'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Curriculum Support',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public curriculumSupport: string;
	// % protected region % [Modify props to the crud options here for attribute 'Curriculum Support'] end

	// % protected region % [Modify props to the crud options here for attribute 'Discipline'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Discipline',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public discipline: string;
	// % protected region % [Modify props to the crud options here for attribute 'Discipline'] end

	// % protected region % [Modify props to the crud options here for attribute 'Duplicate'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Duplicate',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public duplicate: string;
	// % protected region % [Modify props to the crud options here for attribute 'Duplicate'] end

	// % protected region % [Modify props to the crud options here for attribute 'Duplicates'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Duplicates',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public duplicates: string;
	// % protected region % [Modify props to the crud options here for attribute 'Duplicates'] end

	// % protected region % [Modify props to the crud options here for attribute 'Duplicate Selection Summary'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Duplicate Selection Summary',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public duplicateSelectionSummary: string;
	// % protected region % [Modify props to the crud options here for attribute 'Duplicate Selection Summary'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enrol'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Enrol',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public enrol: string;
	// % protected region % [Modify props to the crud options here for attribute 'Enrol'] end

	// % protected region % [Modify props to the crud options here for attribute 'Faculty'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Faculty',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public faculty: string;
	// % protected region % [Modify props to the crud options here for attribute 'Faculty'] end

	// % protected region % [Modify props to the crud options here for attribute 'Location'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Location',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public location: string;
	// % protected region % [Modify props to the crud options here for attribute 'Location'] end

	// % protected region % [Modify props to the crud options here for attribute 'Program'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Program',
		displayType: 'textfield',
		order: 200,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public program: string;
	// % protected region % [Modify props to the crud options here for attribute 'Program'] end

	// % protected region % [Modify props to the crud options here for attribute 'Requirements Fulfilment Check'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Requirements Fulfilment Check',
		displayType: 'textfield',
		order: 210,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public requirementsFulfilmentCheck: string;
	// % protected region % [Modify props to the crud options here for attribute 'Requirements Fulfilment Check'] end

	// % protected region % [Modify props to the crud options here for attribute 'Requisites'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Requisites',
		displayType: 'textfield',
		order: 220,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public requisites: string;
	// % protected region % [Modify props to the crud options here for attribute 'Requisites'] end

	// % protected region % [Modify props to the crud options here for attribute 'Requisite Warning'] off begin
	/**
	 * Displayed when a requisite condition is not met for a given course selection
	 */
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Requisite Warning',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public requisiteWarning: string;
	// % protected region % [Modify props to the crud options here for attribute 'Requisite Warning'] end

	// % protected region % [Modify props to the crud options here for attribute 'Rules'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Rules',
		displayType: 'textfield',
		order: 240,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public rules: string;
	// % protected region % [Modify props to the crud options here for attribute 'Rules'] end

	// % protected region % [Modify props to the crud options here for attribute 'School'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'School',
		displayType: 'textfield',
		order: 250,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public school: string;
	// % protected region % [Modify props to the crud options here for attribute 'School'] end

	// % protected region % [Modify props to the crud options here for attribute 'Selection notice'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Selection notice',
		displayType: 'textfield',
		order: 260,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public selectionNotice: string;
	// % protected region % [Modify props to the crud options here for attribute 'Selection notice'] end

	// % protected region % [Modify props to the crud options here for attribute 'Selection Notice Text'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Selection Notice Text',
		displayType: 'textfield',
		order: 270,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public selectionNoticeText: string;
	// % protected region % [Modify props to the crud options here for attribute 'Selection Notice Text'] end

	// % protected region % [Modify props to the crud options here for attribute 'Student ID'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Student ID',
		displayType: 'textfield',
		order: 280,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public studentID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Student ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Study Period'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Study Period',
		displayType: 'textfield',
		order: 290,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public studyPeriod: string;
	// % protected region % [Modify props to the crud options here for attribute 'Study Period'] end

	// % protected region % [Modify props to the crud options here for attribute 'Study plan'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Study plan',
		displayType: 'textfield',
		order: 300,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public studyPlan: string;
	// % protected region % [Modify props to the crud options here for attribute 'Study plan'] end

	// % protected region % [Modify props to the crud options here for attribute 'Study plans'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Study plans',
		displayType: 'textfield',
		order: 310,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public studyPlans: string;
	// % protected region % [Modify props to the crud options here for attribute 'Study plans'] end

	// % protected region % [Modify props to the crud options here for attribute 'Structure'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Structure',
		displayType: 'textfield',
		order: 320,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public structure: string;
	// % protected region % [Modify props to the crud options here for attribute 'Structure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Structures'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Structures',
		displayType: 'textfield',
		order: 330,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public structures: string;
	// % protected region % [Modify props to the crud options here for attribute 'Structures'] end

	// % protected region % [Modify props to the crud options here for attribute 'Term'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Term',
		displayType: 'textfield',
		order: 340,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public term: string;
	// % protected region % [Modify props to the crud options here for attribute 'Term'] end

	// % protected region % [Modify props to the crud options here for attribute 'Title '] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Title ',
		displayType: 'textfield',
		order: 350,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title '] end

	// % protected region % [Modify props to the crud options here for attribute 'Units'] off begin
	@Validators.Required()
	@observable
	@attribute<LexiconEntity, string>()
	@CRUD({
		name: 'Units',
		displayType: 'textfield',
		order: 360,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public units: string;
	// % protected region % [Modify props to the crud options here for attribute 'Units'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution'] off begin
		name: 'Institution',
		displayType: 'reference-combobox',
		order: 370,
		referenceTypeFunc: () => Models.InstitutionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'institutions',
			oppositeEntity: () => Models.InstitutionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Institution'] end
	})
	public institutionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public institution: Models.InstitutionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ILexiconEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ILexiconEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.academicAdvisor !== undefined) {
				this.academicAdvisor = attributes.academicAdvisor;
			}
			if (attributes.administrator !== undefined) {
				this.administrator = attributes.administrator;
			}
			if (attributes.classDisplayName !== undefined) {
				this.classDisplayName = attributes.classDisplayName;
			}
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.commencingYear !== undefined) {
				this.commencingYear = attributes.commencingYear;
			}
			if (attributes.component !== undefined) {
				this.component = attributes.component;
			}
			if (attributes.components !== undefined) {
				this.components = attributes.components;
			}
			if (attributes.termsAndConditions !== undefined) {
				this.termsAndConditions = attributes.termsAndConditions;
			}
			if (attributes.completedCredit !== undefined) {
				this.completedCredit = attributes.completedCredit;
			}
			if (attributes.course !== undefined) {
				this.course = attributes.course;
			}
			if (attributes.courses !== undefined) {
				this.courses = attributes.courses;
			}
			if (attributes.curriculumSupport !== undefined) {
				this.curriculumSupport = attributes.curriculumSupport;
			}
			if (attributes.discipline !== undefined) {
				this.discipline = attributes.discipline;
			}
			if (attributes.duplicate !== undefined) {
				this.duplicate = attributes.duplicate;
			}
			if (attributes.duplicates !== undefined) {
				this.duplicates = attributes.duplicates;
			}
			if (attributes.duplicateSelectionSummary !== undefined) {
				this.duplicateSelectionSummary = attributes.duplicateSelectionSummary;
			}
			if (attributes.enrol !== undefined) {
				this.enrol = attributes.enrol;
			}
			if (attributes.faculty !== undefined) {
				this.faculty = attributes.faculty;
			}
			if (attributes.location !== undefined) {
				this.location = attributes.location;
			}
			if (attributes.program !== undefined) {
				this.program = attributes.program;
			}
			if (attributes.requirementsFulfilmentCheck !== undefined) {
				this.requirementsFulfilmentCheck = attributes.requirementsFulfilmentCheck;
			}
			if (attributes.requisites !== undefined) {
				this.requisites = attributes.requisites;
			}
			if (attributes.requisiteWarning !== undefined) {
				this.requisiteWarning = attributes.requisiteWarning;
			}
			if (attributes.rules !== undefined) {
				this.rules = attributes.rules;
			}
			if (attributes.school !== undefined) {
				this.school = attributes.school;
			}
			if (attributes.selectionNotice !== undefined) {
				this.selectionNotice = attributes.selectionNotice;
			}
			if (attributes.selectionNoticeText !== undefined) {
				this.selectionNoticeText = attributes.selectionNoticeText;
			}
			if (attributes.studentID !== undefined) {
				this.studentID = attributes.studentID;
			}
			if (attributes.studyPeriod !== undefined) {
				this.studyPeriod = attributes.studyPeriod;
			}
			if (attributes.studyPlan !== undefined) {
				this.studyPlan = attributes.studyPlan;
			}
			if (attributes.studyPlans !== undefined) {
				this.studyPlans = attributes.studyPlans;
			}
			if (attributes.structure !== undefined) {
				this.structure = attributes.structure;
			}
			if (attributes.structures !== undefined) {
				this.structures = attributes.structures;
			}
			if (attributes.term !== undefined) {
				this.term = attributes.term;
			}
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.units !== undefined) {
				this.units = attributes.units;
			}
			if (attributes.institutionId !== undefined) {
				this.institutionId = attributes.institutionId;
			}
			if (attributes.institution !== undefined) {
				if (attributes.institution === null) {
					this.institution = attributes.institution;
				} else if (attributes.institution instanceof Models.InstitutionEntity) {
					this.institution = attributes.institution;
					this.institutionId = attributes.institution.id;
				} else {
					this.institution = new Models.InstitutionEntity(attributes.institution);
					this.institutionId = this.institution.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		institution {
			${Models.InstitutionEntity.getAttributes().join('\n')}
			${Models.InstitutionEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'institution',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public static defaultValues: Omit<ILexiconEntityAttributes, 'institutionId' | 'institution' | keyof IModelAttributes> = {
		academicAdvisor: 'Academic Advisor',
		administrator: 'Administrator',
		classDisplayName: 'Class',
		code: 'Code',
		commencingYear: 'Commencing year',
		component: 'Component',
		components: 'Components',
		termsAndConditions: 'Terms and Conditions',
		completedCredit: 'Completed/Credit',
		course: 'Course',
		courses: 'Courses',
		curriculumSupport: 'Curriculum Support',
		discipline: 'Discipline',
		duplicate: 'Duplicate',
		duplicates: 'Duplicates',
		duplicateSelectionSummary: 'Duplicate Selection Summary',
		enrol: 'Enrol',
		faculty: 'Faculty',
		location: 'Location',
		program: 'Program',
		requirementsFulfilmentCheck: 'Requirements Fulfilment Check',
		requisites: 'Requisites',
		requisiteWarning: 'Requisite condition not met',
		rules: 'Rules',
		school: 'School',
		selectionNotice: 'Selection notice',
		selectionNoticeText: 'Selection notice does not meet requirements',
		studentID: 'Student ID',
		studyPeriod: 'Study period',
		studyPlan: 'Study plan',
		studyPlans: 'Study plans',
		structure: 'Structure',
		structures: 'Structures',
		term: 'Term',
		title: 'Title',
		units: 'Units',
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(LexiconEntity.prototype, 'created');
CRUD(modifiedAttr)(LexiconEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
