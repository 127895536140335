/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportStudyPeriodEntity from 'Models/Security/Acl/CurriculumSupportStudyPeriodEntity';
import VisitorsStudyPeriodEntity from 'Models/Security/Acl/VisitorsStudyPeriodEntity';
import AdminStudyPeriodEntity from 'Models/Security/Acl/AdminStudyPeriodEntity';
import UserStudyPeriodEntity from 'Models/Security/Acl/UserStudyPeriodEntity';
import StudentAdvisorStudyPeriodEntity from 'Models/Security/Acl/StudentAdvisorStudyPeriodEntity';
import InstitutionAdminStudyPeriodEntity from 'Models/Security/Acl/InstitutionAdminStudyPeriodEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IStudyPeriodEntityAttributes extends IModelAttributes {
	name: string;
	order: number;

	programVersionStudyPeriodConfigs: Array<
		| Models.VersionStudyPeriodConfigEntity
		| Models.IVersionStudyPeriodConfigEntityAttributes
	>;
	institutionId: string;
	institution: Models.InstitutionEntity | Models.IInstitutionEntityAttributes;
	terms: Array<
		| Models.TermEntity
		| Models.ITermEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('StudyPeriodEntity', 'Study Period')
// % protected region % [Customise your entity metadata here] end
export default class StudyPeriodEntity extends Model
	implements IStudyPeriodEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportStudyPeriodEntity(),
		new VisitorsStudyPeriodEntity(),
		new AdminStudyPeriodEntity(),
		new UserStudyPeriodEntity(),
		new StudentAdvisorStudyPeriodEntity(),
		new InstitutionAdminStudyPeriodEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Study period name
	 */
	@Validators.Required()
	@observable
	@attribute<StudyPeriodEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order'] on begin
	/**
	 * Order of study period within a year (zero indexed)
	 */
	@Validators.Required()
	@Validators.Integer()
	@Validators.Min(0)
	@Validators.Max(1)
	@observable
	@attribute<StudyPeriodEntity, number>()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
		isReadonly: true,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version Study Period Config'] on begin
		name: 'Program Version Study Period Configs',
		displayType: 'hidden',
		order: 30,
		referenceTypeFunc: () => Models.VersionStudyPeriodConfigEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'programVersionStudyPeriodConfigs',
			oppositeEntity: () => Models.VersionStudyPeriodConfigEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Program Version Study Period Config'] end
	})
	public programVersionStudyPeriodConfigs: Models.VersionStudyPeriodConfigEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution'] on begin
		name: 'Institution',
		displayType: 'hidden',
		order: 30,
		referenceTypeFunc: () => Models.InstitutionEntity,
		headerColumn: true,
		displayFunction: (attribute, model) => {
			return (model as StudyPeriodEntity).institution?.getDisplayName() || '-';
		}
		// % protected region % [Modify props to the crud options here for reference 'Institution'] end
	})
	public institutionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public institution: Models.InstitutionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Term'] off begin
		name: 'Terms',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.TermEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'terms',
			oppositeEntity: () => Models.TermEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Term'] end
	})
	public terms: Models.TermEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IStudyPeriodEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStudyPeriodEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.programVersionStudyPeriodConfigs !== undefined && Array.isArray(attributes.programVersionStudyPeriodConfigs)) {
				for (const model of attributes.programVersionStudyPeriodConfigs) {
					if (model instanceof Models.VersionStudyPeriodConfigEntity) {
						this.programVersionStudyPeriodConfigs.push(model);
					} else {
						this.programVersionStudyPeriodConfigs.push(new Models.VersionStudyPeriodConfigEntity(model));
					}
				}
			}
			if (attributes.institutionId !== undefined) {
				this.institutionId = attributes.institutionId;
			}
			if (attributes.institution !== undefined) {
				if (attributes.institution === null) {
					this.institution = attributes.institution;
				} else if (attributes.institution instanceof Models.InstitutionEntity) {
					this.institution = attributes.institution;
					this.institutionId = attributes.institution.id;
				} else {
					this.institution = new Models.InstitutionEntity(attributes.institution);
					this.institutionId = this.institution.id;
				}
			}
			if (attributes.terms !== undefined && Array.isArray(attributes.terms)) {
				for (const model of attributes.terms) {
					if (model instanceof Models.TermEntity) {
						this.terms.push(model);
					} else {
						this.terms.push(new Models.TermEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		institution {
			${Models.InstitutionEntity.getAttributes().join('\n')}
			${Models.InstitutionEntity.getFiles().map(f => f.name).join('\n')}
		}
		terms {
			${Models.TermEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			programVersionStudyPeriodConfigs: {},
			terms: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] on begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
// const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
// CRUD(createdAttr)(StudyPeriodEntity.prototype, 'created');
// CRUD(modifiedAttr)(StudyPeriodEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
