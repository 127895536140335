/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportLogicGroupEntity from 'Models/Security/Acl/CurriculumSupportLogicGroupEntity';
import AdminLogicGroupEntity from 'Models/Security/Acl/AdminLogicGroupEntity';
import StudentAdvisorLogicGroupEntity from 'Models/Security/Acl/StudentAdvisorLogicGroupEntity';
import InstitutionAdminLogicGroupEntity from 'Models/Security/Acl/InstitutionAdminLogicGroupEntity';
import UserLogicGroupEntity from 'Models/Security/Acl/UserLogicGroupEntity';
import VisitorsLogicGroupEntity from 'Models/Security/Acl/VisitorsLogicGroupEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ILogicGroupEntityAttributes extends IModelAttributes {
	isTopLevelGroup: boolean;
	condition: Enums.logicCondition;
	description: string;

	parentLogicGroupId?: string;
	parentLogicGroup?: Models.LogicGroupEntity | Models.ILogicGroupEntityAttributes;
	childLogicGroups: Array<
		| Models.LogicGroupEntity
		| Models.ILogicGroupEntityAttributes
	>;
	requisiteCourses: Array<
		| Models.RequisiteCourseEntity
		| Models.IRequisiteCourseEntityAttributes
	>;
	assignedCourseId: string;
	assignedCourse: Models.CourseEntity | Models.ICourseEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('LogicGroupEntity', 'Logic Group')
// % protected region % [Customise your entity metadata here] end
export default class LogicGroupEntity extends Model
	implements ILogicGroupEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportLogicGroupEntity(),
		new AdminLogicGroupEntity(),
		new StudentAdvisorLogicGroupEntity(),
		new InstitutionAdminLogicGroupEntity(),
		new UserLogicGroupEntity(),
		new VisitorsLogicGroupEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Is Top Level Group'] off begin
	@observable
	@attribute<LogicGroupEntity, boolean>()
	@CRUD({
		name: 'Is Top Level Group',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isTopLevelGroup: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Top Level Group'] end

	// % protected region % [Modify props to the crud options here for attribute 'Condition'] off begin
	@observable
	@attribute<LogicGroupEntity, Enums.logicCondition>()
	@CRUD({
		name: 'Condition',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.logicConditionOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.logicConditionOptions),
		displayFunction: (attr: Enums.logicCondition) => Enums.logicConditionOptions[attr],
	})
	public condition: Enums.logicCondition;
	// % protected region % [Modify props to the crud options here for attribute 'Condition'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute<LogicGroupEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parent Logic Group'] off begin
		name: 'Parent Logic Group',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.LogicGroupEntity,
		// % protected region % [Modify props to the crud options here for reference 'Parent Logic Group'] end
	})
	public parentLogicGroupId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public parentLogicGroup: Models.LogicGroupEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Child Logic Group'] off begin
		name: 'Child Logic Groups',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.LogicGroupEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'childLogicGroups',
			oppositeEntity: () => Models.LogicGroupEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Child Logic Group'] end
	})
	public childLogicGroups: Models.LogicGroupEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Requisite Course'] off begin
		name: 'Requisite Courses',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.RequisiteCourseEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'requisiteCourses',
			oppositeEntity: () => Models.RequisiteCourseEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Requisite Course'] end
	})
	public requisiteCourses: Models.RequisiteCourseEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Assigned Course'] off begin
		name: 'Assigned Course',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.CourseEntity,
		// % protected region % [Modify props to the crud options here for reference 'Assigned Course'] end
	})
	public assignedCourseId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public assignedCourse: Models.CourseEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ILogicGroupEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ILogicGroupEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isTopLevelGroup !== undefined) {
				this.isTopLevelGroup = attributes.isTopLevelGroup;
			}
			if (attributes.condition !== undefined) {
				this.condition = attributes.condition;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.childLogicGroups !== undefined && Array.isArray(attributes.childLogicGroups)) {
				for (const model of attributes.childLogicGroups) {
					if (model instanceof Models.LogicGroupEntity) {
						this.childLogicGroups.push(model);
					} else {
						this.childLogicGroups.push(new Models.LogicGroupEntity(model));
					}
				}
			}
			if (attributes.parentLogicGroupId !== undefined) {
				this.parentLogicGroupId = attributes.parentLogicGroupId;
			}
			if (attributes.parentLogicGroup !== undefined) {
				if (attributes.parentLogicGroup === null) {
					this.parentLogicGroup = attributes.parentLogicGroup;
				} else if (attributes.parentLogicGroup instanceof Models.LogicGroupEntity) {
					this.parentLogicGroup = attributes.parentLogicGroup;
					this.parentLogicGroupId = attributes.parentLogicGroup.id;
				} else {
					this.parentLogicGroup = new Models.LogicGroupEntity(attributes.parentLogicGroup);
					this.parentLogicGroupId = this.parentLogicGroup.id;
				}
			}
			if (attributes.requisiteCourses !== undefined && Array.isArray(attributes.requisiteCourses)) {
				for (const model of attributes.requisiteCourses) {
					if (model instanceof Models.RequisiteCourseEntity) {
						this.requisiteCourses.push(model);
					} else {
						this.requisiteCourses.push(new Models.RequisiteCourseEntity(model));
					}
				}
			}
			if (attributes.assignedCourseId !== undefined) {
				this.assignedCourseId = attributes.assignedCourseId;
			}
			if (attributes.assignedCourse !== undefined) {
				if (attributes.assignedCourse === null) {
					this.assignedCourse = attributes.assignedCourse;
				} else if (attributes.assignedCourse instanceof Models.CourseEntity) {
					this.assignedCourse = attributes.assignedCourse;
					this.assignedCourseId = attributes.assignedCourse.id;
				} else {
					this.assignedCourse = new Models.CourseEntity(attributes.assignedCourse);
					this.assignedCourseId = this.assignedCourse.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		childLogicGroups {
			${Models.LogicGroupEntity.getAttributes().join('\n')}
		}
		parentLogicGroup {
			${Models.LogicGroupEntity.getAttributes().join('\n')}
		}
		requisiteCourses {
			${Models.RequisiteCourseEntity.getAttributes().join('\n')}
		}
		assignedCourse {
			${Models.CourseEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			childLogicGroups: {},
			requisiteCourses: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'childLogicGroups',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(LogicGroupEntity.prototype, 'created');
CRUD(modifiedAttr)(LogicGroupEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
