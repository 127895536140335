/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import CurriculumSupportAssignedProgramReferenceManyToMany from '../Security/Acl/CurriculumSupportAssignedProgramReferenceManyToMany';
import VisitorsAssignedProgramReferenceManyToMany from '../Security/Acl/VisitorsAssignedProgramReferenceManyToMany';
import AdminAssignedProgramReferenceManyToMany from '../Security/Acl/AdminAssignedProgramReferenceManyToMany';
import StudentAdvisorAssignedProgramReferenceManyToMany from '../Security/Acl/StudentAdvisorAssignedProgramReferenceManyToMany';
import UserAssignedProgramReferenceManyToMany from '../Security/Acl/UserAssignedProgramReferenceManyToMany';
import InstitutionAdminAssignedProgramReferenceManyToMany from '../Security/Acl/InstitutionAdminAssignedProgramReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICampusAssignedProgramVersionAttributes extends IModelAttributes {
	campusId: string;
	assignedProgramVersionId: string;

	campus: Models.CampusEntity | Models.ICampusEntityAttributes;
	assignedProgramVersion: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CampusAssignedProgramVersion')
export default class CampusAssignedProgramVersion
	extends Model
	implements ICampusAssignedProgramVersionAttributes {
	public static acls: IAcl[] = [
		new CurriculumSupportAssignedProgramReferenceManyToMany(),
		new VisitorsAssignedProgramReferenceManyToMany(),
		new AdminAssignedProgramReferenceManyToMany(),
		new StudentAdvisorAssignedProgramReferenceManyToMany(),
		new UserAssignedProgramReferenceManyToMany(),
		new InstitutionAdminAssignedProgramReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public campusId: string;

	@observable
	@attribute()
	public assignedProgramVersionId: string;

	@observable
	@attribute({ isReference: true })
	public campus: Models.CampusEntity;

	@observable
	@attribute({ isReference: true })
	public assignedProgramVersion: Models.ProgramVersionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICampusAssignedProgramVersionAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.campusId) {
				this.campusId = attributes.campusId;
			}
			if (attributes.assignedProgramVersionId) {
				this.assignedProgramVersionId = attributes.assignedProgramVersionId;
			}

			if (attributes.campus) {
				if (attributes.campus instanceof Models.CampusEntity) {
					this.campus = attributes.campus;
					this.campusId = attributes.campus.id;
				} else {
					this.campus = new Models.CampusEntity(attributes.campus);
					this.campusId = this.campus.id;
				}
			} else if (attributes.campusId !== undefined) {
				this.campusId = attributes.campusId;
			}

			if (attributes.assignedProgramVersion) {
				if (attributes.assignedProgramVersion instanceof Models.ProgramVersionEntity) {
					this.assignedProgramVersion = attributes.assignedProgramVersion;
					this.assignedProgramVersionId = attributes.assignedProgramVersion.id;
				} else {
					this.assignedProgramVersion = new Models.ProgramVersionEntity(attributes.assignedProgramVersion);
					this.assignedProgramVersionId = this.assignedProgramVersion.id;
				}
			} else if (attributes.assignedProgramVersionId !== undefined) {
				this.assignedProgramVersionId = attributes.assignedProgramVersionId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
