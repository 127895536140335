/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportVersionStudyPeriodConfig from 'Models/Security/Acl/CurriculumSupportVersionStudyPeriodConfig';
import AdminVersionStudyPeriodConfig from 'Models/Security/Acl/AdminVersionStudyPeriodConfig';
import InstitutionAdminVersionStudyPeriodConfig from 'Models/Security/Acl/InstitutionAdminVersionStudyPeriodConfig';
import StudentAdvisorVersionStudyPeriodConfig from 'Models/Security/Acl/StudentAdvisorVersionStudyPeriodConfig';
import UserVersionStudyPeriodConfig from 'Models/Security/Acl/UserVersionStudyPeriodConfig';
import VisitorsVersionStudyPeriodConfig from 'Models/Security/Acl/VisitorsVersionStudyPeriodConfig';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IVersionStudyPeriodConfigEntityAttributes extends IModelAttributes {
	minimum: number;
	maximum: number;

	studyPeriodId: string;
	studyPeriod: Models.StudyPeriodEntity | Models.IStudyPeriodEntityAttributes;
	programVersionId: string;
	programVersion: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('VersionStudyPeriodConfigEntity', 'Version Study Period Config')
// % protected region % [Customise your entity metadata here] end
export default class VersionStudyPeriodConfigEntity extends Model
	implements IVersionStudyPeriodConfigEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportVersionStudyPeriodConfig(),
		new AdminVersionStudyPeriodConfig(),
		new InstitutionAdminVersionStudyPeriodConfig(),
		new StudentAdvisorVersionStudyPeriodConfig(),
		new UserVersionStudyPeriodConfig(),
		new VisitorsVersionStudyPeriodConfig(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Minimum'] on begin
	/**
	 * Minimum number of units
	 */
	@Validators.Required() // manually added decorator
	@Validators.Numeric()
	@observable
	@attribute<VersionStudyPeriodConfigEntity, number>()
	@CRUD({
		name: 'Minimum',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minimum: number;
	// % protected region % [Modify props to the crud options here for attribute 'Minimum'] end

	// % protected region % [Modify props to the crud options here for attribute 'Maximum'] on begin
	/**
	 * Maximum umber of units
	 */
	@Validators.Required() // manually added decorator
	@Validators.Numeric()
	@observable
	@attribute<VersionStudyPeriodConfigEntity, number>()
	@CRUD({
		name: 'Maximum',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maximum: number;
	// % protected region % [Modify props to the crud options here for attribute 'Maximum'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Period'] off begin
		name: 'Study Period',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.StudyPeriodEntity,
		// % protected region % [Modify props to the crud options here for reference 'Study Period'] end
	})
	public studyPeriodId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public studyPeriod: Models.StudyPeriodEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] off begin
		name: 'Program Version',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] end
	})
	public programVersionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programVersion: Models.ProgramVersionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IVersionStudyPeriodConfigEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IVersionStudyPeriodConfigEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.minimum !== undefined) {
				this.minimum = attributes.minimum;
			}
			if (attributes.maximum !== undefined) {
				this.maximum = attributes.maximum;
			}
			if (attributes.studyPeriodId !== undefined) {
				this.studyPeriodId = attributes.studyPeriodId;
			}
			if (attributes.studyPeriod !== undefined) {
				if (attributes.studyPeriod === null) {
					this.studyPeriod = attributes.studyPeriod;
				} else if (attributes.studyPeriod instanceof Models.StudyPeriodEntity) {
					this.studyPeriod = attributes.studyPeriod;
					this.studyPeriodId = attributes.studyPeriod.id;
				} else {
					this.studyPeriod = new Models.StudyPeriodEntity(attributes.studyPeriod);
					this.studyPeriodId = this.studyPeriod.id;
				}
			}
			if (attributes.programVersionId !== undefined) {
				this.programVersionId = attributes.programVersionId;
			}
			if (attributes.programVersion !== undefined) {
				if (attributes.programVersion === null) {
					this.programVersion = attributes.programVersion;
				} else if (attributes.programVersion instanceof Models.ProgramVersionEntity) {
					this.programVersion = attributes.programVersion;
					this.programVersionId = attributes.programVersion.id;
				} else {
					this.programVersion = new Models.ProgramVersionEntity(attributes.programVersion);
					this.programVersionId = this.programVersion.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		studyPeriod {
			${Models.StudyPeriodEntity.getAttributes().join('\n')}
		}
		programVersion {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(VersionStudyPeriodConfigEntity.prototype, 'created');
CRUD(modifiedAttr)(VersionStudyPeriodConfigEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
