/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import CurriculumSupportGeneratedCompletedCourseReferenceManyToMany from '../Security/Acl/CurriculumSupportGeneratedCompletedCourseReferenceManyToMany';
import AdminGeneratedCompletedCourseReferenceManyToMany from '../Security/Acl/AdminGeneratedCompletedCourseReferenceManyToMany';
import VisitorsGeneratedCompletedCourseReferenceManyToMany from '../Security/Acl/VisitorsGeneratedCompletedCourseReferenceManyToMany';
import StudentAdvisorGeneratedCompletedCourseReferenceManyToMany from '../Security/Acl/StudentAdvisorGeneratedCompletedCourseReferenceManyToMany';
import InstitutionAdminGeneratedCompletedCourseReferenceManyToMany from '../Security/Acl/InstitutionAdminGeneratedCompletedCourseReferenceManyToMany';
import UserGeneratedCompletedCourseReferenceManyToMany from '../Security/Acl/UserGeneratedCompletedCourseReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICompletedByGeneratedPlanGeneratedCompletedCourseAttributes extends IModelAttributes {
	completedByGeneratedPlanId: string;
	generatedCompletedCourseId: string;

	completedByGeneratedPlan: Models.GeneratedPlanEntity | Models.IGeneratedPlanEntityAttributes;
	generatedCompletedCourse: Models.CourseEntity | Models.ICourseEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CompletedByGeneratedPlanGeneratedCompletedCourse')
export default class CompletedByGeneratedPlanGeneratedCompletedCourse
	extends Model
	implements ICompletedByGeneratedPlanGeneratedCompletedCourseAttributes {
	public static acls: IAcl[] = [
		new CurriculumSupportGeneratedCompletedCourseReferenceManyToMany(),
		new AdminGeneratedCompletedCourseReferenceManyToMany(),
		new VisitorsGeneratedCompletedCourseReferenceManyToMany(),
		new StudentAdvisorGeneratedCompletedCourseReferenceManyToMany(),
		new InstitutionAdminGeneratedCompletedCourseReferenceManyToMany(),
		new UserGeneratedCompletedCourseReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public completedByGeneratedPlanId: string;

	@observable
	@attribute()
	public generatedCompletedCourseId: string;

	@observable
	@attribute({ isReference: true })
	public completedByGeneratedPlan: Models.GeneratedPlanEntity;

	@observable
	@attribute({ isReference: true })
	public generatedCompletedCourse: Models.CourseEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICompletedByGeneratedPlanGeneratedCompletedCourseAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.completedByGeneratedPlanId) {
				this.completedByGeneratedPlanId = attributes.completedByGeneratedPlanId;
			}
			if (attributes.generatedCompletedCourseId) {
				this.generatedCompletedCourseId = attributes.generatedCompletedCourseId;
			}

			if (attributes.completedByGeneratedPlan) {
				if (attributes.completedByGeneratedPlan instanceof Models.GeneratedPlanEntity) {
					this.completedByGeneratedPlan = attributes.completedByGeneratedPlan;
					this.completedByGeneratedPlanId = attributes.completedByGeneratedPlan.id;
				} else {
					this.completedByGeneratedPlan = new Models.GeneratedPlanEntity(attributes.completedByGeneratedPlan);
					this.completedByGeneratedPlanId = this.completedByGeneratedPlan.id;
				}
			} else if (attributes.completedByGeneratedPlanId !== undefined) {
				this.completedByGeneratedPlanId = attributes.completedByGeneratedPlanId;
			}

			if (attributes.generatedCompletedCourse) {
				if (attributes.generatedCompletedCourse instanceof Models.CourseEntity) {
					this.generatedCompletedCourse = attributes.generatedCompletedCourse;
					this.generatedCompletedCourseId = attributes.generatedCompletedCourse.id;
				} else {
					this.generatedCompletedCourse = new Models.CourseEntity(attributes.generatedCompletedCourse);
					this.generatedCompletedCourseId = this.generatedCompletedCourse.id;
				}
			} else if (attributes.generatedCompletedCourseId !== undefined) {
				this.generatedCompletedCourseId = attributes.generatedCompletedCourseId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
