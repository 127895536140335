/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import CurriculumSupportProgramRuleCourseReferenceManyToMany from '../Security/Acl/CurriculumSupportProgramRuleCourseReferenceManyToMany';
import AdminProgramRuleCourseReferenceManyToMany from '../Security/Acl/AdminProgramRuleCourseReferenceManyToMany';
import InstitutionAdminProgramRuleCourseReferenceManyToMany from '../Security/Acl/InstitutionAdminProgramRuleCourseReferenceManyToMany';
import StudentAdvisorProgramRuleCourseReferenceManyToMany from '../Security/Acl/StudentAdvisorProgramRuleCourseReferenceManyToMany';
import UserProgramRuleCourseReferenceManyToMany from '../Security/Acl/UserProgramRuleCourseReferenceManyToMany';
import VisitorsProgramRuleCourseReferenceManyToMany from '../Security/Acl/VisitorsProgramRuleCourseReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProgramRuleCourseAttributes extends IModelAttributes {
	programRuleId: string;
	courseId: string;

	programRule: Models.ProgramRuleEntity | Models.IProgramRuleEntityAttributes;
	course: Models.CourseEntity | Models.ICourseEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ProgramRuleCourse')
export default class ProgramRuleCourse
	extends Model
	implements IProgramRuleCourseAttributes {
	public static acls: IAcl[] = [
		new CurriculumSupportProgramRuleCourseReferenceManyToMany(),
		new AdminProgramRuleCourseReferenceManyToMany(),
		new InstitutionAdminProgramRuleCourseReferenceManyToMany(),
		new StudentAdvisorProgramRuleCourseReferenceManyToMany(),
		new UserProgramRuleCourseReferenceManyToMany(),
		new VisitorsProgramRuleCourseReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public programRuleId: string;

	@observable
	@attribute()
	public courseId: string;

	@observable
	@attribute({ isReference: true })
	public programRule: Models.ProgramRuleEntity;

	@observable
	@attribute({ isReference: true })
	public course: Models.CourseEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IProgramRuleCourseAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.programRuleId) {
				this.programRuleId = attributes.programRuleId;
			}
			if (attributes.courseId) {
				this.courseId = attributes.courseId;
			}

			if (attributes.programRule) {
				if (attributes.programRule instanceof Models.ProgramRuleEntity) {
					this.programRule = attributes.programRule;
					this.programRuleId = attributes.programRule.id;
				} else {
					this.programRule = new Models.ProgramRuleEntity(attributes.programRule);
					this.programRuleId = this.programRule.id;
				}
			} else if (attributes.programRuleId !== undefined) {
				this.programRuleId = attributes.programRuleId;
			}

			if (attributes.course) {
				if (attributes.course instanceof Models.CourseEntity) {
					this.course = attributes.course;
					this.courseId = attributes.course.id;
				} else {
					this.course = new Models.CourseEntity(attributes.course);
					this.courseId = this.course.id;
				}
			} else if (attributes.courseId !== undefined) {
				this.courseId = attributes.courseId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
